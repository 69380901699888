<template>
    <div>
        <div v-loading="$store.state.loading">
            <ul class="report-list">
                <li
                v-for="(i, index) in $store.state.text"
                :key="index">
                    <a
                    :href="'/detail--' + i.id + '.html'"
                    target="_blank">
                        <el-image v-if="index <= 40" :src="require('@/assets/' + index + '.jpg')" lazy></el-image>
                        <el-image v-else :src="require('@/assets/' + (index % 40) + '.jpg')" lazy></el-image>
                        <div class="report-con">
                            <div class="report-title">
                                <p>报告</p>
                                <h1 v-html="i.title"></h1>
                            </div>
                            <div class="report-time">
                                <p>{{ i.post_date.split(/T|Z/).join(' ') }}</p>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>

        <div
        class="more"
        v-if="$store.state.more">
            <el-button
            type="primary"
            plain
            @click="$store.dispatch('getText')">
            查看更多</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Report',
}
</script>