<template>
    <div>
        <div
        class="project"
        style="min-height: 400px">
            <a
            v-for="(i, index) of $store.state.ztList.slice(0, $store.state.ztSize)"
            :key="index"
            :href="i.body"
            target="_blank">
                <div class="zt-con">
                    <div class="zt-banner">
                        <el-image :src="'' + i.image" lazy></el-image>
                    </div>
                    <p v-html="i.title"></p>
                </div>
            </a>
        </div>

        <div
        class="more"
        v-if="$store.state.ztMore">
            <el-button
            type="primary"
            plain
            @click="$store.commit('addProject')">
            查看更多</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Project',
}
</script>