<template>
    <div class="content-box">
        <Title/>

        <div
        class="tab"
        v-loading="$store.state.loading">
            <el-table
            :data="$store.state.SmallappTab"
            stripe>
                <el-table-column
                label="序号"
                type="index"
                width="60">
                </el-table-column>
                <el-table-column
                prop="investor"
                label="logo"
                width="100">
                <template slot-scope="scope">
                    <el-image :src="scope.row.investor" style="width:50px;" lazy></el-image>
                </template>
                </el-table-column>
                <el-table-column
                prop="financiers"
                label="名称"
                width="150">
                </el-table-column>
                <el-table-column
                prop="home"
                label="关联公众号数"
                width="120">
                </el-table-column>
                <el-table-column
                prop="time"
                label="公众号预估阅读量"
                width="140">
                </el-table-column>
                <el-table-column
                prop="rounds"
                label="成长指数">
                </el-table-column>
                <el-table-column
                prop="amount"
                label="阿拉丁指数">
                </el-table-column>
            </el-table>
        </div>

        <div class="more">
            <el-button
            type="primary"
            plain
            @click="$store.commit('getDsb')">
            查看更多</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Smallapp',
};

</script>

<style scoped>
.el-table--enable-row-transition .el-table__body td {
    height: 94px;
}
.el-button--primary.is-plain {
    width: 100%;
}
</style>