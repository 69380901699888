<template>
    <div>
        <div v-loading="$store.state.loading">
            <div
            v-for="(i, index) of $store.state.dataNav"
            :key="index"
            style="position:relative;">
                <div class="main" :id="'main' + index"></div>
                <div class="watermark"></div>
            </div>
        </div>

        <div class="more">
            <el-button
            type="primary"
            plain
            @click="$store.commit('getDsb')">
            查看更多</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Echarts',
}
</script>