<template>
    <div class="submit-box">
        <div class="submit-btn-box">
            <el-button style="width: 100%;" type="primary" plain @click="dialogFormVisible = true">加入俱乐部</el-button>
            <div
            class="mobile-submit"
            @click="dialogFormVisible = true">
                <i class="el-icon-user-solid"></i>
                <p>俱乐部</p>
            </div>
        </div>

        <el-dialog
        :title="$store.state.submit + '精英俱乐部'"
        :visible.sync="dialogFormVisible"
        :modal-append-to-body="false">
            <el-form
            action="/Home/Index/submit_info"
            method="post"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            id="postForm"
            class="demo-ruleForm">
                <el-form-item
                style="display: none;"
                label="行业"
                prop="lingyu">
                    <el-input name="lingyu" v-model="ruleForm.lingyu"></el-input>
                </el-form-item>

                <el-form-item
                label="姓名"
                prop="name">
                    <el-input name="name" v-model="ruleForm.name"></el-input>
                </el-form-item>

                <el-form-item
                label="部门/职务"
                prop="position">
                    <el-input name="position" v-model="ruleForm.position"></el-input>
                </el-form-item>

                <el-form-item
                label="公司/平台"
                prop="company">
                    <el-input name="company" v-model="ruleForm.company"></el-input>
                </el-form-item>

                <el-form-item
                label="地址"
                prop="address">
                    <el-input name="address" v-model="ruleForm.address"></el-input>
                </el-form-item>

                <el-form-item
                label="邮箱"
                prop="email">
                    <el-input name="email" v-model="ruleForm.email"></el-input>
                </el-form-item>

                <el-form-item
                label="手机"
                prop="mobile">
                    <el-input name="mobile" v-model="ruleForm.mobile"></el-input>
                </el-form-item>

                <el-form-item
                class="vcode-box"
                label="验证码"
                prop="vcode">
                    <el-input name="vcode" v-model="ruleForm.vcode"></el-input>
                    <el-button
                    style="width: 120px; margin-left: 20px;"
                    type="primary"
                    @click="getVcode"
                    :disabled="vcodeBtn != '发送验证码' ? true : false">
                    {{vcodeBtn}}</el-button>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm('ruleForm')">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Submit',
    data() {
        return {
            //表单
            dialogFormVisible: false,
            labelPosition: 'right',
            ruleForm: {
                lingyu: this.$store.state.submit,
                name: '',
                position: '',
                company: '',
                address: '',
                email: '',
                mobile: '',
                vcode: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入姓名',
                        trigger: 'blur'
                    },
                ],
                position: [
                    {
                        required: true,
                        message: '请输入部门/职务',
                        trigger: 'blur'
                    }
                ],
                company: [
                    {
                        required: true,
                        message: '请输入公司/平台',
                        trigger: 'blur'
                    }
                ],
                email: [
                    {
                        required: true,
                        message: '请输入邮箱地址',
                        trigger: 'blur'
                    },
                    {
                        type: 'email',
                        message: '请输入正确邮箱地址',
                        trigger: ['blur', 'change']
                    }
                ],
                mobile: [
                    {
                        required: true,
                        message: '请输入手机号码',
                        trigger: 'blur'
                    },
                    {
                        min: 11,
                        max: 11,
                        message: '请输入正确手机号码',
                        trigger: ['blur', 'change']
                    }
                ],
                vcode: [
                    {
                        required: true,
                        message: '请输入验证码',
                        trigger: 'blur'
                    },
                    {}
                ]
            },
            vcodeBtn: '发送验证码',
            vcodeNum: 60,
        }
    },
    methods: {
        getVcode() {
            if(this.ruleForm.mobile.length != 11) {
                this.$message.error('请输入正确手机号码');
            }else {
                axios({
				    method: 'post',
				    url: '/Home/Index/send_phone_msg',
				    data:{
				    	mobile: this.ruleForm.mobile
				    },
			    }).then((res) => {
                    if(res.data.code == 1) {
                        var time = setInterval(() => {
	                        if(this.vcodeNum > 0) {
                                this.vcodeNum -= 1
                                this.vcodeBtn = this.vcodeNum
                            }else {
                                clearInterval(time)
                                this.vcodeNum = 60
                                this.vcodeBtn = '发送验证码'
                            }
                        }, 1000)
                    }else if(res.data.code == 2) {
                        this.$message.error(res.data.msg)
                    }
                })
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if(valid) {
                    this.$refs.ruleForm.$el.submit()
                }else {
                    return false
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.dialogFormVisible = false
        }
    }
}
</script>