<template>
    <div class="top-bg">

        <div class="top-box">
            <div class="logo">
                <a
                href=""
                target="_blank">
                    <img :src="require('@/assets/logo.png')">
                </a>
            </div>

            <div class="top-right">
                <div class="nav">
                    <el-menu
                    class="el-menu-demo"
                    mode="horizontal"
                    :active-text-color="$store.state.color.bar0"
                    :default-active="this.$route.path">
                        <el-submenu
                        v-for="(i, index) of $store.state.allNav"
                        :key="index"
                        :index="i.name">
                            <template slot="title">{{i.name}}</template>
                            <div
                            v-for="(j, index) of i.child"
                            :key="index">
                                <div v-if="j.url.indexOf('/') == -1">
                                    <el-menu-item
                                    :index="'/' + j.url"
                                    @click="$router.push({name: j.url})">
                                    {{j.name}}</el-menu-item>
                                </div>
                                
                                <div v-else>
                                    <el-menu-item>
                                        <el-link
                                        :underline="false"
                                        :href="j.url"
                                        target="_blank">
                                        {{j.name}}</el-link>
                                    </el-menu-item>
                                </div>
                            </div>
                        </el-submenu>
                    </el-menu>
                </div>

                <div class="search">
                    <el-input
                    id="search_text"
                    type="text"
                    autocomplete="on"
                    placeholder="键入搜索关键词"
                    v-model="search_keywords"
                    @keyup.enter.native="getSearch()">
                        <el-button
                        slot="append"
                        icon="el-icon-search"
                        @click="getSearch()"></el-button>
                    </el-input>
                </div>
                
                <!--移动端搜索按钮-->
                <div class="move-search">
                    <el-button
                    icon="el-icon-search"
                    circle
                    @click="moveShow()">
                    </el-button>
                </div>
            </div>

            <!--移动端搜索-->
            <div
            class="move-searchBox"
            :style="moveStyle">
                <el-input
                placeholder="键入搜索关键词"
                prefix-icon="el-icon-search"
                v-model="search_keywords"
                @keyup.enter.native="getSearch()">
                </el-input>
                <el-button
                type="primary"
                icon="el-icon-search"
                style="border: none;color: #fff;font-size: 30px;padding: 5px;margin-left: 10px;"
                @click="getSearch()">
                </el-button>
                <el-button
                icon="el-icon-close"
                type="primary"
                style="border: none;color: #fff;font-size: 30px;padding: 5px;"
                @click="moveShow()">
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            drawer: false,
            moveSearch: false,
            moveStyle: {},
            search_keywords: ''
        }
    },
    methods: {
        getSearch: function() {
            if(this.search_keywords != ''){
                window.open('/Index/wjs_search_res.html?kw=' + this.search_keywords)
            }else{
                alert('请输入关键词！')
            }
        },
        moveShow() {
            this.moveSearch = !this.moveSearch
            if(this.moveSearch == true) {
                this.moveStyle = {
                    top: '0',
                    opacity: '1'
                }
            }else {
                this.moveStyle = {
                    top: '-60px',
                    opacity: '0'
                }
            }
        },
    }
}
</script>