<template>
    <div class="company-box">
        <el-tabs
        v-model="$store.state.companyCategory.a102"
        type="card"
        @tab-click="handleClick">
            <el-tab-pane
            v-for="(i, index) of $store.state.companyTabs"
            :key="index"
            :label="i"
            :name="i">
                <div class="company">
                    <el-link
                    v-for="(i, index) in $store.state.companyList"
                    :key="index"
                    :href="'/enterprise/#/Inside?name=' + i.name"
                    target="_blank">
                    {{i.name}}</el-link>
                </div>
            </el-tab-pane>
        </el-tabs>
        
        <div
        v-if="$store.state.companyList.length >= 12"
        class="batch-box">
            <el-button
            round
            @click="getPage()">
            换一批</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Company',
    created() {
        this.$store.dispatch('getEnterprise')
    },
    methods: {
        handleClick() {
            this.$store.commit('inBatch', 1)
            this.$store.dispatch('getEnterprise')
        },
        getPage() {
            this.$store.commit('inBatch')
            this.$store.dispatch('getEnterprise')
        }
    }
}
</script>