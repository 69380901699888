<template>
    <div>
        <div class="footer">
            <div class="footer-main">
                <About/>
    
                <div class="taiwan">
                    <span>专业台矩阵</span>
                    <Taiwan/>

                    <!-- <span>合作伙伴</span>
                    <ul class="partner-list">
                        <li>
                            <a>
                                <img src="//imgs-b2b.toocle.com/Public/home/images/wjsviders/logo/aks.jpg">
                            </a>
                        </li>
                    </ul> -->
                </div>
    
                <div class="qrcode">
                    <div class="wx-public">
                        <p>微信公众号</p>
                        <img src="//imgs-b2b.toocle.com/Public/home/images/wx-public.jpg">
                        <p>扫码关注</p>
                    </div>

                    <div class="wx-public">
                        <p>微信小程序</p>
                        <img src="//imgs-b2b.toocle.com/Public/home/images/code/wjs_xcx.jpg">
                        <p>扫码体验</p>
                    </div>
    
                    <div class="wx-private">
                        <p>商务合作</p>
                        <img src="//imgs-b2b.toocle.com/Public/home/images/o2o-private.jpg">
                        <p>联系陈老师</p>
                    </div>
                </div>
            </div>

            <Record/>
        </div>

        <el-dialog
		class="cooperation"
		:visible.sync="$store.state.coopeShow"
		width="20%"
		center
		:show-close="false">
            <h1>商务合作/媒体采访：</h1>
			<p>联系人：陈老师</p>
			<p>电话：0571-85337217</p>
			<p>手机：15168211391</p>
			<p>邮箱：O2O@netsun.com</p>
            <span slot="footer" class="dialog-footer">
			    <el-button type="primary" @click="$store.commit('getBusiness', false)">我知道了</el-button>
		    </span>
		</el-dialog>
    </div>
</template>

<script>
import { About } from 'shared'
import { Taiwan } from 'shared'
import { Record } from 'shared'

export default {
    name: 'Footer',
    components: {
        About,
        Taiwan,
        Record
    }
}
</script>