import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../theme/index.css'
import WeVue from 'we-vue'
Vue.use(WeVue)

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.transformRequest = [function (data) {
    let src = ''
    for (let item in data) {
        src += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&'
    }
    return src
}]

import { Title } from 'shared'
Vue.component('Title', Title)
import { Textlist } from 'shared'
Vue.component('Textlist', Textlist)
import { Echarts } from 'shared'
Vue.component('Echarts', Echarts)
import { Email } from 'shared'
Vue.component('Email', Email)
import { Submit } from 'shared'
Vue.component('Submit', Submit)
import { Company } from 'shared'
Vue.component('Company', Company)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    var width = document.body.clientWidth
    if(width > 768) {
        // chrome
        document.body.scrollTop = 0
        // firefox
        document.documentElement.scrollTop = 0
        // safari
        window.pageYOffset = 0
    }else {
        // chrome
        document.body.scrollTop = 290
        // firefox
        document.documentElement.scrollTop = 290
        // safari
        window.pageYOffset = 290
    }
    next()
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
