<template>
    <div id="app">
        <div class="pc-box">
            <Header/>
            <Banner/>
            <div class="content">
                <div class="content-left">
                    <router-view/>
                </div>
                <div class="content-right">
                    <ContentR/>
                </div>
            </div>
            <Suspension/>
            <Footer/>
            <Mobile/>
        </div>
    </div>
</template>

<script>
import { Header } from 'shared'
import { Banner } from 'shared'
import ContentR from './views/ContentR'
import Footer from './views/Footer'
import { Suspension } from 'shared'
import { Mobile } from 'shared'

export default {
    components: {
        Header,
        Banner,
        ContentR,
        Footer,
        Suspension,
        Mobile
    },
    created() {
        this.$store.commit('getTaiwan', 'FC')
        this.$store.dispatch('getBannerZt', '房产')
        this.$store.dispatch('getBannerArticle', '房产')
        this.$store.commit('getSubmit', '房产')
        this.$store.commit('dataColor', {
            bar0: '#ff8615',  
            bar1: '#04a23f',
            line0: '#ff6a04',
            line1: '#fbae08'
        })
    },
}
</script>

<style>
/* #app .nav ul li:last-child {
    display: none;
} */

#app .right-box .form .btn:hover {
    color: #fff;
    background-color: #ff8615;
    border-color: #ff8615;
}

.company h2::before,
.content-right .platform li:hover,
.move-searchBox,
.report-con .report-title p,
.report-list li a:hover .report-con,
.tsb a:hover span {
    background: #ff8615;
}

.el-menu--horizontal .el-menu .el-menu-item:hover {
    color: #ff8615 !important;
    background: #eee !important;
}
.el-menu--horizontal .el-menu .el-menu-item:hover span {
    color: #ff8615 !important;
}

.content-left .title {
    border-left: 5px solid #ff8615;
}
.content-left .title h2 span {
    color: #ff8615;
    padding: 0 10px;
}

.content-right .right-title a:hover,
.company-title a:hover,
#app .right-box .form .btn,
.text a:hover span,
.content-left .more a,
.project a:hover p,
.report-list li a:hover .report-title p,
.tsb a:hover p,
.mobile-bottom .current,
.mobile-top .current2,
.mobile-submit i,
.backtop:hover,
.code-box:hover {
    color: #ff8615;
}
.el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 2px solid #ff8615 !important;
    color: #303133;
}
.el-tabs__item.is-active {
    color: #ff8615 !important;
}
@media(max-width: 868px) {
  .banner-right .banner-right-m p {
    background: #ff8615;
  }
}
</style>