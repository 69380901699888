<template>
    <div
    v-if="mobileShow"
    class="mobile-box">

        <ul class="mobile-top">
            <div class="drawer-left"></div>

            <li
            v-for="(i, index) of $store.state.buttons[current].child"
            :key="index"
            @click="toRouter2(index)"
            :class="{current2:index == current2}">
            {{i.name}}</li>
            <li style="flex: 0 0 10%"></li>

            <div
            class="drawer-btn"
            :class="[drawer == true ? 'drawer-up': 'drawer-down']">
                <i
                class="el-icon-arrow-down"
                @click="drawer = !drawer"></i>
            </div>
        </ul>

        <el-drawer
        :visible.sync="drawer"
        direction="ttb">
            <h1>导航推荐</h1>

            <div v-if="$store.state.companyList.length > 0">
                <h2>企业库<span>Enterprise library</span></h2>

                <Company/>
            </div>

            <div
            v-for="(i, index) of $store.state.dropList"
            :key="index">
                <h2 v-if="i.child.length != 0">{{i.name}}<span>{{i.english}}</span></h2>
                <ul v-if="i.child.length != 0" class="drawer-list">
                    <li
                    v-for="(j, index) of i.child"
                    :key="index">
                        <a
                        :href="j.url"
                        target="_blank">
                        {{j.name}}</a>
                    </li>
                </ul>
            </div>

            <div>
                <h2>邮箱订阅<span>subscribe</span></h2>
                <Email/>
            </div>
        </el-drawer>

        <ul class="mobile-bottom">
            <li
            v-for="(i, index) of $store.state.buttons"
            :key="index"
            @click="toRouter(index)"
            :class="{current:index == current}">
                <i :class="i.icon"></i>
                <p>{{i.name}}</p>
            </li>
            <Submit/>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Mobile',
    data() {
        return {
            mobileShow: false,
            current: 0,
            current2: 0,
            drawer: false,
            checked : true
        }
    },
    mounted() {
        setTimeout(() => {
            for(var i in this.$store.state.buttons) {
                for(var j in this.$store.state.buttons[i].child) {
                    if('/' + this.$store.state.buttons[i].child[j].url == this.$route.fullPath) {
                        this.current = i
                        this.current2 = j
                    }
                }
            }
            this.mobileShow = true
        }, 1000)
    },
    methods: {
        toRouter(index) {
            this.current = index
            this.current2 = 0
            this.$router.push({name: this.$store.state.buttons[index].url})
        },
        toRouter2(index) {
            this.current2 = index
            this.$router.push({name: this.$store.state.buttons[this.current].child[index].url})
        }
    }
}
</script>