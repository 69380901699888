<template>
    <div class="right-box">
        <div class="right-title">
            <span>免费订阅</span>
            <a href="/zt/dszk/" target="_blank">往期回顾</a>
        </div>
            
        <div class="cheked">
            <el-checkbox v-model="checked" disabled>快评</el-checkbox>
            <el-checkbox v-model="checked" disabled>报告</el-checkbox>
            <el-checkbox v-model="checked" disabled>专题</el-checkbox>
            <el-checkbox v-model="checked" disabled>原创</el-checkbox>
            <el-checkbox v-model="checked" disabled>数据</el-checkbox>
        </div>

        <form role="form" action="http://www.100ec.cn/emailadd.html" method="post" class="form">
            <input type="email" class="form-control" id="email" name="email" placeholder="请输入您的邮箱">
            <button type="submit" class="btn">邮箱订阅</button>
        </form>

        <Submit/>
    </div>
</template>

<script>
export default {
    name: 'Email',
    data() {
        return {
            checked : true,
        }
    },
}
</script>