<template>
    <div class="suspension">
        <!-- <div class="code-box">
            <el-popover
            placement="left-start"
            title="如果您是在线教育业内人士，扫一扫"
            width="150"
            trigger="hover">
                <div>
                    <img
                    style="width:100%;height:100%;object-fit: cover;"
                    :src="require('@/assets/wx-private.jpg')"
                    alt/>
                </div>
                <div slot="reference">
                    <i class="fa fa-qrcode"></i>
                </div>
            </el-popover>
        </div> -->

        <div
        class="backtop"
        @click="backtop"
        v-if="btnFlag">
            <i class="el-icon-caret-top"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Suspension',
    data() {
        return {
            scrollTop: '',
            btnFlag: false,
        }
    },
    mounted() {
       window.addEventListener("scroll", this.showbtn);
    },
    methods: {
        showbtn() {
            this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if(this.scrollTop > 300) {
                this.btnFlag = true
            }else {
                this.btnFlag = false
            }
        },
        backtop() {
            var timer = setInterval(function() {
                var osTop = document.documentElement.scrollTop || document.body.scrollTop;
                var ispeed = Math.floor(-osTop / 5); 
                document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                this.isTop = true;
                if(osTop === 0) {
                  clearInterval(timer);
                }
            },30)
        }
    },
}
</script>