<template>
    <div class="about">
        <span>关于我们</span>
        <ul class="about-list">
            <li>
                <a
		    	href="/about/contactus.html"
		    	target="_blank">
		    	联系我们</a>
            </li>
            <li>
                <a
		    	href="/zt/zxdsj/"
		    	target="_blank">
		    	发展历程</a>
            </li>
            <li>
                <a @click="$store.commit('getBusiness', true)">商务合作</a>
            </li>
            <li>
                <a
		    	href="http://my.315.100ec.cn/index.php?_a=login&login_way=green_user"
		    	target="_blank">
		    	投诉入驻</a>
            </li>
            <li>
                <a
		    	href="/about/submit.html"
		    	target="_blank">
		    	投稿撤稿</a>
            </li>
            <li>
                <a
		    	href="/about/mian.html"
		    	target="_blank">
		    	免责声明</a>
            </li>
            <li>
                <a
		    	href="/Index/Wjsproviders.html"
		    	target="_blank">
		    	产品服务</a>
            </li>
            <li>
                <a
		    	href="/Index/material"
		    	target="_blank">
		    	媒体素材</a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>