<template>
    <div class="content-box">
        <div class="title">
            <h2>评级榜</h2>

            <el-menu
            :default-active="activeIndex"
            class="el-menu-vertical-demo">
                <el-submenu index="1">
                    <template slot="title">
                        <i class="el-icon-menu"></i>
                        数据字段
                    </template>
                    <el-menu-item-group>
                        <el-menu-item
                        :index="String('1-' + index)"
                        v-for="(i, index) of dataNav"
                        :key="index"
                        @click="getData(index)">
                        {{i.name}}</el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
            </el-menu>
        </div>

        <Echarts/>

        <div
        class="tab"
        v-loading="loading">
        <el-table
        :data="tableData"
        stripe>
            <el-table-column
            label="排名"
            type="index"
            width="50">
            </el-table-column>
            <el-table-column
            prop="name"
            label="平台名称">
            </el-table-column>
            <el-table-column
            prop="pre"
            label="反馈率"
            sortable>
            </el-table-column>
            <el-table-column
            prop="feedback"
            label="时效性"
            sortable>
            </el-table-column>
            <el-table-column
            prop="score_ave"
            label="满意度"
            sortable>
            </el-table-column>
            <el-table-column
            prop="buy"
            label="综合指数"
            sortable>
            </el-table-column>
            <el-table-column
            prop="grade"
            label="评级">
            </el-table-column>
            <el-table-column
            prop=""
            label="详情">
                <template slot-scope="scope">
                    <el-link
                    :href="'/Index/complaintDataDetail.html?website=' + scope.row.name"
                    target="blank">
                    数据详情</el-link>
                </template>
            </el-table-column>
        </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: "rating",
    data() {
        return {
            loading: false,
            dataNav: [
                {
                    name: "综合指数",
                    data: "buy",
                    
                },
                {
                    name: "满意度",
                    data: "score_ave",
                    
                },
                {
                    name: "回复时效性",
                    data: "feedback",
                    
                },
                {
                    name: "平台反馈率",
                    data: "pre",
                    
                }
            ],
            activeIndex: '1-0',
            params: {},
            dataList: [],
            tableData: [] //表格数据
        }
    },
    created() {
        this.loading = true
        this.$store.commit('openLoading')

        this.$store.commit('depositDataNav', this.dataNav)

        if(new Date().getMonth >= 2) {
            this.params = {
                type: this.$store.state.type,
                year: new Date().getFullYear()
            }
        }else {
            this.params = {
                type: this.$store.state.type,
                year: new Date().getFullYear() - 1,
                time: 'whole_year'
            }
        }
        
        axios({
			method: 'post',
			url: 'http://www.315.100ec.cn/api/index.php?_a=data',
			data: this.params
		}).then((res) => {
            this.dataList = res.data.list
            this.getData(0)
            for(var i of res.data.list) {
                this.tableData.push(
                    {
                        name: i.company,
                        pre: i.pre,
                        feedback: i.feedback,
                        score_ave: i.score_ave,
                        buy: i.buy,
                        grade: i.if_green == 0 && i.buy == 0 ? '不予评级' : i.grade,
                    }
                )
            }
            this.loading = false
            this.$store.commit('closeLoading')
        })
    },
    methods: {
        getData(index) {
            var nav = []
            nav.push(this.dataNav[index])
            this.$store.commit('depositDataNav', nav)

            var handle = (property) => {
                return function(a, b) {
                    var val1 = a[property]
                    var val2 = b[property]
                    return val2 - val1
                }
            }

            var axisX = []
            var dataBar = []
            var arr = this.dataList.sort(handle(this.dataNav[index].data))
            for(var i of arr) {
                for(var k in i) {
                    if(k == this.dataNav[index].data && i[k] != 0) {
                        axisX.push(i.company)
                        dataBar.push(i[k])
                    }
                }
            }

            this.$store.commit('initialData')

            this.$store.commit('ratingData', {
                time: this.params.year,
                axisX: axisX,
                dataBar: dataBar
            })

            this.$store.commit('showBar', 0)
        },
    },
}
</script>