<template>
    <div>
        <div v-loading="$store.state.loading">
            <div class="text">
                <a
                v-for="(i, index) in $store.state.text"
                :key="index"
                :href="'/detail--' + i.id + '.html'"
                target="_blank">
                <div class="img">
                    <el-image v-if="index <= 40" :src="require('@/assets/' + index + '.jpg')" lazy></el-image>
                    <el-image v-else :src="require('@/assets/' + (index % 40) + '.jpg')" lazy></el-image>
                </div>
                <div class="con">
                    <span v-html="i.title"></span>
                    <p>{{ i.post_date.split(/T|Z/).join(' ') }}</p>
                </div>
                </a>
            </div>
        </div>

        
        <div
        v-if="$store.state.more"
        class="more">
            <el-button
            type="primary"
            plain
            @click="$store.dispatch('getText')">
            查看更多</el-button>
        </div>
    </div>
</template>


<script>
export default {
    name: 'Textlist'
}
</script>