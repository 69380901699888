<template>
    <div class="content-box">
        <Title/>

        <el-date-picker
        v-model="time"
        type="month"
        placeholder="选择月"
        format="yyyy 年 MM 月"
        value-format="yyyyMM"
        :editable="false"
        :clearable="false"
        @change="getTime">
        </el-date-picker>

        <div
        class="tab"
        v-loading="loading">
        <el-table
        :data="tableData"
        stripe>
            <el-table-column
            label="排名"
            type="index"
            width="60">
            </el-table-column>
            <el-table-column
            prop=""
            label="Logo"
            width="100">
                <template slot-scope="scope">
                    <el-image :src="scope.row.logo" style="width:50px;" lazy></el-image>
                </template>
            </el-table-column>
            <el-table-column
            prop="application"
            label="应用名">
            </el-table-column>
            <el-table-column
            prop="number"
            label="月活人数(万)">
            </el-table-column>
            <el-table-column
            prop="growth"
            label="环比增幅(%)">
                <template slot-scope="scope">
                    <span
                    v-if="parseFloat(scope.row.growth) > 0"
                    style="color:#d33237;">
                    {{scope.row.growth}}</span>
                    <span
                    v-else
                    style="color:#0c9d76;">
                    {{scope.row.growth}}</span>
                </template>
            </el-table-column>
        </el-table>
        </div>

        <div class="more">
            <el-button
            type="primary"
            plain
            @click="$store.commit('getDsb')">
            查看更多</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Monthlive',
    data() {
        return {
            frist: true,
            loading: false,
            year: new Date().getFullYear(),
            month: new Date().getMonth()+1,
            time: '',
            dataList: [],
            tableData: [],
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            if(this.frist == true) {
                if(this.month < 10) {
                    this.month = '0' + this.month
                }
                this.time = this.year + '' + this.month
            }
            axios({
				method: "post",
				url: "/Index/url_json",
				data:{
					url: 'http://bjx.iimedia.cn/applicationRank',
                    time: this.time,
                    offset: 0,
                    limit: 20,
                    main_type: this.$store.state.monthType.main,
                    sub_type: this.$store.state.monthType.sub,
                    orderBy: 0,
                    order: 0,
				},
			}).then((res) => {
                if(res.data.length == 0 && this.frist == true) {
                    if(this.year == new Date().getFullYear() && this.month < 2) {
                        this.year = new Date().getFullYear()-1
                        this.month = 12
                    }else {
                        this.month -= 1
                    }
                    return this.getData()
                }else {
                    this.frist = false
                    this.dataList = res.data
                    this.tableData = []
                    for(var i = 0; i < this.dataList.length; i++) {
                        this.tableData.push(
                            {
                                logo: this.dataList[i].avator,
                                application: this.dataList[i].app_name,
                                number: (this.dataList[i].uv / 10000).toFixed(2),
                                growth: this.dataList[i].rank_ratio != null ? this.dataList[i].rank_ratio.toFixed(2) + '%' : 0 + '%'
                            }
                        )
                    }
                    this.loading = false
                }
            })
        },
        getTime(value) {
            this.time = value
            this.getData()
        },
    }
}
</script>

<style>
.el-button--primary.is-plain {
    width: 100%;
}
</style>