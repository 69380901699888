<template>
    <ul class="taiwan-list">
        <li v-for="(i, index) of $store.state.taiwan"
        :key="index">
            <a
	    	:href="'http://' + i.url + '.100ec.cn/'"
	    	target="_blank">
            {{i.name}}</a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'Taiwan'
}
</script>