<template>
  <div>
    <div class="right-box">
      <div class="right-title">
        <span>热门公司</span>
        <a
          href="https://www.100ec.cn/enterprise/#/list?field=%E6%95%B0%E5%AD%97%E9%9B%B6%E5%94%AE&category=%E6%9C%8D%E8%A3%85%E7%94%B5%E5%95%86"
          target="_blank"
          >更多</a
        >
      </div>

      <Company />
    </div>

    <Email />

    <!-- <div style="width: 365px">
      <a href="https://www.100ec.cn/zt/wswjsxfpgyl/" target="_blank">
        <img style="width: 100%" src="/Public/home/images/zyttk/xfpjr.jpg" />
      </a>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "ContentR",
  data() {
    return {
      params: {
        field: "数字生活",
        tabs: [
          "淘宝",
          "天猫",
          "京东",
          "拼多多",
          "唯品会",
          "抖音",
          "快手",
          "视频号",
          "小红书",
          "国美",
          "亚马逊",
          "当当",
          "凡客诚品",
          "南极电商",
          "酷特智能",
          "戎美股份",
          "百丽优购",
          "醒购商城",
          "衣二三",
          "知衣科技",
          "优衣库",
          "衣邦人",
          "韩都衣舍",
          "森马",
          "银泰",
          "茵曼",
        ],
      },
    };
  },
  created() {
    this.$store.commit("getCompanyTabs", this.params);
  },
};
</script>