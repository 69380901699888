<template>
  <div class="banner">
    <div class="banner-left">
      <el-carousel style="height: 100%">
        <el-carousel-item v-for="(i, index) of $store.state.zt" :key="index">
          <a :href="i.body" target="_blank">
            <div class="banner-shadow"></div>
            <img :src="'' + i.image" />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="banner-right">
      <div class="banner-right-pc">
        <a
          v-for="(i, index) of $store.state.article"
          :key="index"
          :href="'/detail--' + i.id + '.html'"
          target="_blank"
        >
          <div class="banner-shadow"></div>
          <img
            :src="require('@/assets/' + ($store.state.random + index) + '.jpg')"
          />
          <p>{{ i.title }}</p>
        </a>
      </div>

      <ul class="banner-right-m">
        <li>
          <span>热点</span>
          <p>网经社</p>
        </li>
        <li>
          <el-carousel direction="vertical" indicator-position="none">
            <el-carousel-item
              v-for="(i, index) of $store.state.article"
              :key="index"
            >
              <a :href="'/detail--' + i.id + '.html'" target="_blank">
                {{ i.title }}</a
              >
            </el-carousel-item>
          </el-carousel>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
};
</script>