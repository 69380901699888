<template>
    <div class="content-box">
        <Title/>

        <div style="display: flex;">
        <el-select
        v-model="year"
        @change="getYear">
            <el-option
            v-for="item in yearArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>

        <el-select
        v-model="time"
        @change="getTime"
        style="margin-left: 20px;">
            <el-option
            v-for="item in timeArr"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
        </div>

        <div
        style="min-height:100px;"
        v-loading="loading">
            <div class="tsb">
                <a
                v-for="(item, index) of dataList"
                :key="index"
                :href="'/Index/complain_search.html?company=' + item.company"
                target="_blank">
                    <span>{{index + 1}}</span>
                    <p>{{item.company}}</p>
                </a>
            </div>
        </div>

        <div
        class="noData"
        v-if="noData">
        暂无数据</div>

        <div class="tsb_note">
            <p>（以上为投诉量排行榜）</p>
            <p>由于投诉量的多少与平台的市场占有率有直接关联，为避免被竞争对手和部分媒体关注且恶意放大，故不显示。</p> <p>因新平台于2018年315前夕正式上线运行，在此前投诉数据未予统计故未显示。</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ComplainList',
    data() {
        return {
            loading: false,
            thisYear: new Date().getFullYear(),
            today: new Date().toLocaleDateString().slice(5),
            yearArr: [],
            year: '',
            options: [
                {
                    todo: '',
                    value: '',
                    label: '至今'
                },
                {
                    todo: '13/1',
                    value: 'whole_year',
                    label: '全年'
                },
                {
                    todo: '5/31',
                    value: 'first_half',
                    label: '上半年'
                },
                {
                    todo: '3/31',
                    value: 'first_quarter',
                    label: '第一季度'
                },
                {
                    todo: '6/30',
                    value: 'second_quarter',
                    label: '第二季度'
                },
                {
                    todo: '9/30',
                    value: 'third_quarter',
                    label: '第三季度'
                },
                {
                    todo: '12/31',
                    value: 'fourth_quarter',
                    label: '第四季度'
                },
                {
                    todo: '2/1',
                    value: '1_month',
                    label: '1月'
                },
                {
                    todo: '3/1',
                    value: '2_month',
                    label: '2月'
                },
                {
                    todo: '4/1',
                    value: '3_month',
                    label: '3月'
                },
                {
                    todo: '5/1',
                    value: '4_month',
                    label: '4月'
                },
                {
                    todo: '6/1',
                    value: '5_month',
                    label: '5月'
                },
                {
                    todo: '7/1',
                    value: '6_month',
                    label: '6月'
                },
                {
                    todo: '8/1',
                    value: '7_month',
                    label: '7月'
                },
                {
                    todo: '9/1',
                    value: '8_month',
                    label: '8月'
                },
                {
                    todo: '10/1',
                    value: '9_month',
                    label: '9月'
                },
                {
                    todo: '11/1',
                    value: '10_month',
                    label: '10月'
                },
                {
                    todo: '12/1',
                    value: '11_month',
                    label: '11月'
                },
                {
                    todo: '13/1',
                    value: '12_month',
                    label: '12月'
                },
                {
                    todo: '6/18',
                    value: '_618',
                    label: '618'
                },
                {
                    todo: '11/11',
                    value: 'single_day',
                    label: '双11'
                },
            ],
            timeArr: [],
            time: '',
            dataList: [],
            activeIndex: '1',
            noData: false
        }
    },
    mounted() {
        this.getDate()
        this.getOptions()
        this.getData()
    },
    methods: {
        //日期判断
        getDate() {
            if(Number(this.today.split("/")[0]) >= 3) {
                for(var i = this.thisYear; i >= 2019; i--) {
                    this.yearArr.push(
                        {
                            value: i,
                            label: i
                        }
                    )
                }
            }else {
                for(var i = this.thisYear-1; i >= 2019; i--) {
                    this.yearArr.push(
                        {
                            value: i,
                            label: i
                        }
                    )
                }
            }
            this.year = this.yearArr[0].value
        },
        getData() {
            this.loading = true
            axios.get('http://www.315.100ec.cn/api/index.php?_a=product&f=most_company_list&type=' + this.$store.state.type + '&limit=&year=' + this.year +'&time=' + this.time)
            .then((res) => {
                this.dataList = res.data.list
                if(this.dataList.length == 0) {
                    this.noData = true
                }else {
                    this.noData = false
                }
                this.loading = false
            })
        },
        getYear(year) {
            this.getOptions()
            this.getData()
        },
        getOptions() {
            this.timeArr = []
            for(var i of this.options) {
                if(this.year != this.thisYear) {
                    if(i.label != '至今') {
                        this.timeArr.push(i)
                    }
                }else {
                    if(Number(i.todo.split("/")[0]) < Number(this.today.split("/")[0]) || i.label == '至今') {
                        this.timeArr.push(i)
                    }else if(Number(i.todo.split("/")[0]) == Number(this.today.split("/")[0])) {
                        if(Number(i.todo.split("/")[1]) < Number(this.today.split("/")[1])) {
                            this.timeArr.push(i)
                        }
                    }
                }
            }
            this.time = this.timeArr[0].value
        },
        getTime(time) {
            this.getData()
        }
    }
}
</script>

<style scoped>
.tsb a:hover span {
    background: #0845a0;
}
.tsb a:hover p {
    color: #0845a0;
}
</style>