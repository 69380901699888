<template>
    <div class="content-box">
        <Title/>

        <div
        class="tab"
        v-loading="loading">
        <el-table
        :data="tableData"
        stripe>
            <el-table-column
            label="序号"
            type="index"
            width="60">
            </el-table-column>
            <el-table-column
            prop="name"
            label="平台名"
            width="160px">
            </el-table-column>
            <el-table-column
            prop="home"
            label="所在地"
            width="100px">
            </el-table-column>
            <el-table-column
            prop="online"
            label="上线时间">
            </el-table-column>
            <el-table-column
            prop="shutdown"
            sortable
            label="关停时间">
            </el-table-column>
            <el-table-column
            prop="financing"
            label="融资情况"
            width="100px">
            </el-table-column>
            <el-table-column
            prop="survive"
            label="存活时间">
            </el-table-column>
        </el-table>
        </div>

        <div class="more">
            <el-button
            type="primary"
            plain
            @click="$store.commit('getDsb')">
            查看更多</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeathData',
    data() {
        return {
            loading: false,
            dataList: [],
            tableData: [],
            size: 20,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            axios({
				method:"post",
				url:"/Index/new_data_json?dataid=9",
				data:{
                    pagesize: 1000,
                    shares: this.$store.state.death.shares
				},
			}).then((res)=>{
                if(this.$store.state.death.a100 != '') {
                    for(var i of res.data.list) {
                        if(this.$store.state.death.a100.indexOf(i.a100) != -1) {
                            this.dataList.push(i)
                        }
                    }
                    this.dataList.reverse()
                }else {
                    this.dataList = res.data.list.reverse()
                }
                
                if(this.size > this.dataList.length) {
                    this.size = this.dataList.length
                }
                
                for(var i = 0; i < this.size; i++) {
                    this.tableData.push(
                        {
                            name: this.dataList[i].name,
                            home: this.dataList[i].a106,
                            online: this.dataList[i].a101,
                            shutdown: this.dataList[i].a103,
                            financing: this.dataList[i].a108,
                            survive: this.dataList[i].a109,
                        }
                    )
                }
                this.loading = false
			})
        },
    }
}
</script>

<style>
.el-button--primary.is-plain {
    width: 100%;
}
</style>