// import Vue from 'vue'
// import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
        path: '/',
        redirect: {
            name: "broadcast"
        }
    },
    {
        path: '/broadcast',
        name: 'broadcast',
        component: () =>
            import ('@/components/information/broadcast.vue'),
    },
    {
        path: '/financing',
        name: 'financing',
        component: () =>
            import ('@/components/information/financing.vue'),
    },
    {
        path: '/project',
        name: 'project',
        component: () =>
            import ('@/components/information/project.vue'),
    },
    {
        path: '/report',
        name: 'report',
        component: () =>
            import ('@/components/research/report.vue'),
    },
    {
        path: '/Research',
        name: 'Research',
        component: () =>
            import ('@/components/research/Research.vue'),
    },
    {
        path: '/industry',
        name: 'industry',
        component: () =>
            import ('@/components/data/industry.vue'),
    },
    {
        path: '/jcfinanceData',
        name: 'jcfinanceData',
        component: () =>
            import ('@/components/data/jcfinanceData.vue'),
    },
    {
        path: '/financeData',
        name: 'financeData',
        component: () =>
            import ('@/components/data/financeData.vue'),
    },
    {
        path: '/complainList',
        name: 'complainList',
        component: () =>
            import ('@/components/complaints/complainList.vue'),
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
})

export default router