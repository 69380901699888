<template>
    <ul class="footer-bottom">
        <li><a href="http://www.100ec.cn/">网经社&nbsp;版权所有</a></li>
       	<li><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP证：浙B2-20090135-4</a></li>
       	<li><a href="http://www.100ec.cn/Public/home/images/ICPzs.jpg" target="_blank">ICP证书</a></li>
        <li><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602009379" target="_blank"><img src="http://www.100ec.cn/Public/home/images/ghs.png" style="float: left;padding: 15px 0px"></a><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602009379" target="_blank">浙公网安备&nbsp;33010602009379号</a></li>
        <!-- <li><script src="https://s13.cnzz.com/z_stat.php?id=1273076332&web_id=1273076332" language="JavaScript"></script></li> -->
        <li><a href="http://www.100ec.cn/zt/upload_data/sbzc_38.pdf" target="_blank">商标注册证：38类 </a><a href="http://www.100ec.cn/zt/upload_data/sbzc_41.pdf" target="_blank">41类</a></li>
    </ul>
</template>

<script>
export default {
    name: 'Record'
}
</script>