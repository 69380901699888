import WeVue from 'we-vue'
// import 'we-vue/lib/style.css'
import Vue from 'vue'
import Header from './src/components/Header'
import Banner from './src/components/Banner'
import Title from './src/components/Title'
import Email from './src/components/Email'
import Submit from './src/components/Submit'
import Company from './src/components/Company'
import About from './src/components/About'
import Taiwan from './src/components/Taiwan'
import Record from './src/components/Record'
import Suspension from './src/components/Suspension'
import Mobile from './src/components/Mobile'
import Smallapp from './src/components/Smallapp'
// import Advertising from './src/components/Advertising'
import Textlist from './src/components/Textlist'
import Project from './src/components/Project'
import Report from './src/components/Report'
import Echarts from './src/components/Echarts'
import FinanceData from './src/components/FinanceData'
import Appstore from './src/components/Appstore'
import Monthlive from './src/components/Monthlive'
import DeathData from './src/components/DeathData'
import ComplainList from './src/components/ComplainList'
import Rating from './src/components/Rating'
Vue.use(WeVue)
export {
    Header,
    Banner,
    Title,
    Email,
    Submit,
    Company,
    About,
    Taiwan,
    Record,
    Suspension,
    Mobile,
    Smallapp,
    // Advertising
    Textlist,
    Project,
    Report,
    Echarts,
    FinanceData,
    Appstore,
    Monthlive,
    DeathData,
    ComplainList,
    Rating
}