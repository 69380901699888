<template>
    <div class="content-box">
        <Title/>

        <div
        class="tab"
        v-loading="$store.state.loading">
            <el-table
            :data="$store.state.financTab"
            stripe>
                <el-table-column
                label="序号"
                type="index"
                width="60">
                </el-table-column>
                <el-table-column
                prop="financiers"
                label="融资方"
                width="120">
                </el-table-column>
                <el-table-column
                prop="home"
                label="所在地"
                width="120">
                </el-table-column>
                <el-table-column
                prop="time"
                label="融资时间"
                width="140">
                </el-table-column>
                <el-table-column
                prop="rounds"
                label="融资轮次">
                </el-table-column>
                <el-table-column
                prop="amount"
                label="融资金额">
                </el-table-column>
                <el-table-column
                prop="investor"
                label="投资方"
                width="200">
                </el-table-column>
            </el-table>
        </div>

        <div class="more">
            <el-button
            type="primary"
            plain
            @click="$store.commit('getDsb')">
            查看更多</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FinanceData'
}

</script>

<style scoped>
.el-table--enable-row-transition .el-table__body td {
    height: 94px;
}
.el-button--primary.is-plain {
    width: 100%;
}
</style>