<template>
    <div class="title">
        <h2>{{title}}</h2>

        <el-link
        v-if="this.more.name != ''"
        :href="this.more.url"
        target="_blank"
        :underline="false">
        {{this.more.name}}</el-link>
    </div>
</template>


<script>
export default {
    name: 'Title',
    data() {
        return {
            title: '',
            more: {
                name: '',
                url: ''
            }
        }
    },
    created() {
        this.$store.state.allNav.forEach(item => {
            item.child.forEach(j => {
                if(this.$route.path.indexOf(j.url) != -1) {
                    this.title = j.name
                    if(this.title == '首页') {
                        this.title = '热点聚焦'
                    }

                    if(item.more != undefined) {
                        this.more = item.more
                    }else if(j.more != undefined){
                        this.more = j.more
                    }
                }
            })
        })
    }
}
</script>