<template>
    <div class="content-box">
        <Title/>

        <div style="display: flex;">
        <el-select
        v-model="listName"
        @change="getList">
            <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>

        <el-select
        v-if="$store.state.genreIds.length > 1"
        v-model="genreName"
        @change="getGenre"
        style="margin-left: 20px;">
            <el-option
            v-for="item in $store.state.genreIds"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>

        <el-select
        v-model="equipName"
        @change="getEquip"
        style="margin-left: 20px;">
            <el-option
            v-for="item in equipment"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
        </div>

        <div
        class="tab"
        v-loading="loading">
        <el-table
        :data="tableData"
        stripe>
            <el-table-column
            label="排名"
            type="index"
            width="60">
            </el-table-column>
            <el-table-column
            prop=""
            label="Logo"
            width="100">
                <template slot-scope="scope">
                    <el-image :src="scope.row.logo" style="width:50px;" lazy></el-image>
                </template>
            </el-table-column>
            <el-table-column
            prop="application"
            label="应用">
            </el-table-column>
            <el-table-column
            prop="developers"
            label="开发者">
            </el-table-column>
            <el-table-column
            prop="shelftime"
            label="上架时间"
            width="160">
            </el-table-column>
        </el-table>
        </div>

        <div class="more">
            <el-button
            type="primary"
            plain
            @click="$store.commit('getDsb')">
            查看更多</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'appstore',
    data() {
        return {
            loading: false,
            appList: [
                // 免费榜
                {
                    list: [
                        // iphone
                        'topfreeapplications',
                        // ipad
                        'topfreeipadapplications'
                    ]
                },
                // 付费榜
                {
                    list: [
                        // iphone
                        'toppaidapplications',
                        // ipad
                        'toppaidipadapplications'
                    ]
                },
                // 畅销榜
                {
                    list: [
                        // iphone
                        'topgrossingapplications',
                        // ipad
                        'topgrossingipadapplications'
                    ]
                },
            ],
            json: '',

            options: [
                {
                    value: 0,
                    label: '免费榜'
                },
                {
                    
                    value: 1,
                    label: '付费榜'
                },
                {
                    
                    value: 2,
                    label: '畅销榜'
                },
            ],
            listName: '免费榜',
            listIndex: 0,

            genreName: '',
            genreValue: '',

            equipment: [
                {
                    value: 0,
                    label: 'iPhone'
                },
                {
                    value: 1,
                    label: 'iPad'
                },
            ],
            equipName: 'iphone',
            equipIndex: 0,
            dataList: [],
            tableData: [],
        }
    },
    mounted() {
        this.genreName = this.$store.state.genreIds[0].label
        this.genreValue = this.$store.state.genreIds[0].value
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            this.json = 'https://itunes.apple.com/cn/rss/' + this.appList[this.listIndex].list[this.equipIndex] + '/limit=20/genre=' + this.genreValue + '/json'
            axios.get(this.json)
            .then((res) => {
                this.dataList = res.data.feed.entry
                if(this.size > this.dataList.length) {
                    this.moreShow = false
                }else {
                    this.moreShow = true
                }
                this.tableData = []
                for(var i = 0; i < this.dataList.length; i++) {
                    this.tableData.push(
                        {
                            logo: this.dataList[i]["im:image"][0].label,
                            application: this.dataList[i]["im:name"].label,
                            developers: this.dataList[i]["im:artist"].label,
                            shelftime: this.dataList[i]["im:releaseDate"].attributes.label
                        }
                    )
                }
                this.loading = false
            })
        },
        getList(value) {
            this.listIndex = value
            this.getData()
        },
        getGenre(value) {
            this.genreValue = value
            this.getData()
        },
        getEquip(value) {
            this.equipIndex = value
            this.getData()
        },
    }
}
</script>

<style>
.el-button--primary.is-plain {
    width: 100%;
}
</style>